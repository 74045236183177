import React from "react"
import Layout from "../Layout"
import ExternalLink from "../exitramp/ExternalLink.jsx"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import AppProvider, { AppContext } from "../../contexts/state"
import pricingPdf from "../../assets/images/myfembree-pricing.pdf"
import dlIcon from "../../assets/images/dl_icon.png"

class SiteMapContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    // this.props.handleActiveHeaderNav('');
  }

  render() {
    return (
      <Layout>
        <section className="sitemap-container">
          <Container>
            <Row>
              <Col lg={{ span: 12 }}>
                <h1 className="white">Site Map</h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="sitemap-list-container">
          <Container>
            <Row>
              <Col lg={{ span: 12 }}>
                <ul className="purple-ul sitemap-ul">
                  <li>
                    <Link to={"/#what-is-endometriosis"}>
                      What Is Endometriosis?
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#meet-myfembree"}>Meet Myfembree</Link>
                  </li>
                  <li>
                    <Link to={"/#relieves-pain"}>For 3 Types of Endo Pain</Link>
                  </li>
                  <li>
                    <Link to={"/#side-effects"}>Side Effects</Link>
                  </li>
                  <li>
                    <Link to={"/#cost-support"}>Cost & Support</Link>
                  </li>
                </ul>
                <ul className="purple-ul sitemap-ul sub-ul">
                  <li>
                    <Link to="/commercial-insurance/">
                      Copay Assistance Program
                    </Link>
                  </li>
                  <li>
                    <ExternalLink externalUrl="https://portal.trialcard.com/myovant/myfembree/consent/">
                      Provide e-Consent
                    </ExternalLink>
                  </li>
                  <li>
                    <a target="_blank" href={pricingPdf}>
                      <span>Out-of-Pocket Cost</span>
                      <img src={dlIcon} />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default SiteMapContent
